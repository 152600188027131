import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const Post = props => {
  const { isLast, node } = props
  return (
    <div
      style={{
        borderBottom: `${isLast ? "0" : "2px"} gray solid`,
      }}
    >
      <Link to={node.fields.slug}>
        <h2>{node.frontmatter.title}</h2>
      </Link>
      {node.frontmatter.image ? (
        <Image fluid={node.frontmatter.image.childImageSharp.fluid} />
      ) : null}
      <div dangerouslySetInnerHTML={{ __html: node.html }} />
    </div>
  )
}

const IndexPage = props => {
  const { data } = props
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Home" />
      <div>
        {posts.map(({ node }, index) => (
          <Post isLast={index === posts.length - 1} key={node.id} node={node} />
        ))}
      </div>
    </Layout>
  )
}

export default IndexPage
